.vertical-text {
    transform: rotate(90deg);
    transform-origin: left top 0;
    width: 150px;
    position: absolute;
    left: 50px;
    text-align: center;
    color: white;
}
.vertical-text-result-page {
    transform: rotate(90deg);
    transform-origin: left top 0;
    width: 150px;
    position: absolute;
    left: 120px;
    text-align: center;
    color: white;
}

.team-td {
    padding: 10px;
    border: 10px solid #efefef;

}

ß .team-td thead th {
    font-weight: bolder;
    background-color: #efefef;
}

.game-result {}

.game-result .airport-name {
    background-color: #f9de5b;
}

.game-result .img-container {
    border: 1px solid #f9de5b;
}

.game-result .col-md-12 {
    margin-bottom: 0px;
}

.game-result .target-reached {
    background-color: #434343;
    color: white;
}
.game-result .best-team {
    background-color: #f9de5b;
}
.game-result img {
    max-width: 160% !important;
    margin-left: -30%;
    margin-right: -30%;
}

.highcharts-credits {
    display: none !important;
}